import React, { Component } from 'react';
import Web3 from 'web3';
import Identicon from 'identicon.js';
import './App.css';
import Decentragram from '../abis/Decentragram.json'
import Navbar from './Navbar'
import Main from './Main'


// import ipfsClient from 'ipfs-http-client';
// const ipfs = ipfsClient({ host: 'ipfs.infura.io', port: 5001, protocol: 'https',headers: { authorization: auth } });
// const authorization = `Basic ` + ipfsClient.Buffer.from(document.querySelector('#projectId').value.trim()+ ':' + document.querySelector('#projectSecret').value.trim()).toString('base64');
// const ipfs = window.IpfsApi('ipfs.infura.io', '5001', { protocol: 'https' })
// const ipfs = IpfsHttpClient('ipfs.infura.io', '5001', { protocol: 'https' })
// const ipfs = window.ipfsClient.create({ host: 'ipfs.infura.io 1', port: 5001, protocol: 'https', headers: {authorization} })

// const ipfs = window.IpfsHttpClient.create({
//   host: 'ipfs.infura.io 1', port: 5001, protocol: 'https', headers: { authorization: auth },
// })

const ipfsClient = require('ipfs-http-client');
const projectId = '2G8JNuOrzqu3SYCU68H6sbo5gES';
const projectSecret = '1e89c13b88bc1ab681ea32e688b2fa36';
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');

const ipfs = ipfsClient({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});

class App extends Component {

  async componentDidMount(){
  // async componentWillMount(){
    await this.loadWeb3()
    await this.loadBlockchainData()
  }
  
  async loadWeb3(){
    if(window.ethereum){
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }else if(window.web3){
      window.web3 = new Web3(window.web3.currentProvider)
    }else{
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
    }
  }
  
  async loadBlockchainData() {
    const web3 = window.web3
    // Load account
    const accounts = await web3.eth.getAccounts()
    this.setState({ account: accounts[0] })

    // Network ID
    const networkId = await web3.eth.net.getId()
    const networkData = Decentragram.networks[networkId]
    if(networkData){
      const decentragram = web3.eth.Contract(Decentragram.abi, networkData.address)

      this.setState({ decentragram })
      const imagesCount = await decentragram.methods.imageCount().call()
      console.log('Inii '+imagesCount);
      this.setState({ imagesCount })

      // load images
      for(let i = 1; i <= imagesCount; ++i){
        const image = await decentragram.methods.images(i).call()
        this.setState({
          images: [...this.state.images, image]
        })
        console.log(this.state.images);
        console.log('INI IMAGENYA : ', image);
      }

      // Sort images. Show higest tipped images first
      this.setState({
        images: this.state.images.sort((a, b) => b.tipAmount - a.tipAmount)
      })
      
      this.setState({ loading: false })
    }else{
      window.alert('Decentragram contract not deployed to detected network!')
    }
  }

  captureFile = event => {
    event.preventDefault()
    const file = event.target.files[0]
    const reader = new window.FileReader()
    reader.readAsArrayBuffer(file)

    reader.onloadend = () => {
      this.setState({ buffer: Buffer(reader.result) })
      console.log('buffer', this.state.buffer)
    }
    console.log('Inii2 ');
  }

  uploadImage = description => {
    console.log('Submitting file to ipfs');
    
    // Adding file to the IPFS
    ipfs.add(this.state.buffer, (error, result) => {
      console.log('Ipfs result', result);
      if(error){
        console.error(error)
        return
      }

      this.setState({ loading: true })
      this.state.decentragram.methods.uploadImage( result[0].hash, description ).send({ from: this.state.account }).on('transactionHash', (hash) => {
        this.setState({ loading: false })
      })
    });
    console.log('Inii3 ');
  }
  
  tipImageOwner = (id, tipAmount) => {
    this.setState({ loading: true })
    this.state.decentragram.methods.tipImageOwner(id).send({ from: this.state.account, value: tipAmount }).on('transactionHash', (hash) => {
      this.setState({ loading: false })
    })
  }
  
  constructor(props) {
    super(props)
    this.state = {
      account: '',
      decentragram: null,
      // images: ['tes1', 'tes2', 'tes3'],
      images: [],
      loading: true
    }
  }

  render() {
    return (
      <div>
        <Navbar account={this.state.account} />
        { this.state.loading
          ? <div id="loader" className="text-center mt-5"><p>Loading...</p></div>
          : <Main
              images={this.state.images}
              captureFile={this.captureFile}
              uploadImage={this.uploadImage}
              tipImageOwner={this.tipImageOwner}
            />
        }
      </div>
    );
  }
}

export default App;